export default {
  loadCountries: (state, data) => {
    let countries = data.map(item => {
      return {
        value: item.id,
        text: item.name,
        phonecode: item.phonecode
      }
    })

    state.countries = countries
    state.states = []
    state.cities = []
  },
  loadStates: (state, data) => {
    let states = data.map(item => {
      return {
        value: item.id,
        text: item.name
      }
    })

    state.states = states
    state.cities = []
  },
  loadCities: (state, data) => {
    let city = data.map(item => {
      return {
        value: item.id,
        text: item.name
      }
    })

    state.cities = city
  },

  resetState: state => {
    state.states = []
  },
  resetCity: state => {
    state.cities = []
  }
}