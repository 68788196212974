import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import dashboard from './dashboard'
import corporate from './corporate'
import partner from './partner'
import member from './member'
import banner from './banner'
import event from './event'
import survey from './survey'
import csc from './csc'
import role from './role'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,

    dashboard,
    corporate, partner,
    member, banner,
    event, csc, role, survey
  },
  strict: process.env.DEV,
  state: {
    config: {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    },
    gender_options: [
      { value: 'man', text: 'Man' },
      { value: 'woman', text: 'Woman' }
    ],
    work_options: [
      { value: 'crafting', text: 'Crafting' },
      { value: 'fashion', text: 'Fashion' },
      { value: 'culinary', text: 'Culinary' },
      { value: 'merchant', text: 'Merchant' },
      { value: 'teacher', text: 'Teacher' },
      { value: 'agricultural', text: 'Agricultural' },
      { value: 'banking', text: 'Banking' },
      { value: 'others', text: 'Others' }
    ],
    occupation_options: [
      { value: 'chef', text: 'Chef' },
      { value: 'cashier', text: 'Cashier' },
      { value: 'barber', text: 'Barber' },
      { value: 'dentist', text: 'Dentist' },
      { value: 'dentist', text: 'Dentist' },
      { value: 'driver', text: 'Driver' },
      { value: 'teacher', text: 'Teacher' },
      { value: 'tailor', text: 'Tailor' },
      { value: 'mechanic', text: 'Mechanic' },
      { value: 'engineer', text: 'Engineer' },
      { value: 'salesman', text: 'Salesman' },
      { value: 'porter', text: 'Porter' },
      { value: 'doctor', text: 'Doctor' },
      { value: 'employee', text: 'Employee' },
      { value: 'businessman', text: 'Businesman' },
      { value: 'others', text: 'Others' },
    ],
    education_options: [
      { value: 'sd', text: 'SD' },
      { value: 'smp', text: 'SMP' },
      { value: 'sma/smk', text: 'SMA/SMK' },
      { value: 'diploma', text: 'Diploma' },
      { value: 'sarjana', text: 'Sarjana' },
      { value: 'pasca sarjana', text: 'Pasca Sarjana' },
    ],
    socioeconomic_options: [
      { value: 'mikro', text: 'Mikro (< 300 Jt)' },
      { value: 'kecil', text: 'Kecil (300 Jt s/d 2.5 M)' },
      { value: 'menengah', text: 'Kecil (2.5 M s/d 50 M)' },
      { value: 'besar', text: 'Besar (> 50 M)' },
    ],
    provider_options: [
      { value: 'xl', text: 'XL' },
      { value: 'indosat', text: 'Indosat' },
      { value: 'telkomsel', text: 'Telkomsel' },
      { value: 'byu', text: 'By.U' },
      { value: 'other', text: 'Other' },
    ],
    phonetype_options: [
      { value: 'samsung', text: 'Samsung' },
      { value: 'iphone', text: 'Iphone' },
      { value: 'oppo', text: 'Oppo' },
      { value: 'other', text: 'Other' }
    ],
    socmed_options: [
      { value: 'facebook', text: 'Facebook' },
      { value: 'twitter', text: 'Twitter' },
      { value: 'instagram', text: 'Instagram' },
      { value: 'linkedin', text: 'Linkedin' },
      { value: 'youtube', text: 'Youtube' },
      { value: 'tiktok', text: 'Tiktok' }
    ]
  }
})
