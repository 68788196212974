export default {
  banner: [],
  detail: {
    id: null,
    title: null,
    link: null,
    description: null,
    image: null,
    corporate: null,
    file: {
      url: null,
      image: null
    },
    file_raw: null
  }
}