import state from "./state"

export default {
  loadBanner: (state, data) => {
    state.banner = data
  },
  loadDetailBanner: (state, data) => {
    let detail = state.detail

    detail.id = data.id
    detail.title = data.banner_name
    detail.link = data.external_link
    detail.description = data.banner_description
    detail.image = data.image
    detail.file.url = data.image
    detail.corporate = data.corporate_id
  },
  resetBanner: state => {
    let detail = state.detail

    detail.title = null
    detail.link = null
    detail.description = null
    detail.image = null
    detail.corporate = null
    detail.file = {
      url: null,
      image: null
    }
    detail.file_raw = null
  }
}