import axios from '@/libs/axios'

export default {
  loadMember: context => {
    const role = localStorage.getItem('role')
    
    if(role == 'corporate') {
      axios.get(`/member/corporate/user`, context.rootState.config)
        .then(res => {
          let member = res.data.data

          context.commit('loadMember', member)
        })
    } else {
      axios.get(`/noauth/member`)
        .then(res => {
          let member = res.data.data
          context.commit('loadMember', member)
        })
    }
  },
  loadDetailMember: (context, id) => {
    axios.get(`/noauth/member/${id}`)
      .then(res => {
        const member = res.data.data.member

        if(member.corporate_id) {
          context.commit('loadMemberCorporate', member.corporate)
        } else {
          context.commit('loadMemberCompany', member.company)
        }
        
        context.commit('loadMemberDetail', member)
        context.commit('loadMemberSocmed', member.socmed)
        context.commit('loadMemberAddress', member.address)
      })
  },
  addMember: (context, data) => {
    return new Promise((resolve, reject) => {
      axios.post('noauth/member/add', data, context.rootState.config)
        .then(res => {
          context.commit('eventMember', res.data)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  editMember: (context, data) => {
    return new Promise((resolve, reject) => {
      axios.put(`noauth/member/edit/${data.id}`, data, context.rootState.config)
        .then(res => {
          context.commit('eventMember', res.data)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteMember: (context, id) => {
    return new Promise((resolve, reject) => {
      axios.delete(`noauth/member/${id}`)
        .then(res => {
          context.commit('eventMember', res.data)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  resetDetailMember: context => {
    context.commit('resetDetailMember')
  }
}