export default {
  loadDashboard: (state, data) => {
    state.age = data.age
    state.business_cat = data.business_cat
    state.education = data.education
    state.man_member = data.man_member
    state.occupation = data.occupation
    state.provider = data.provider
    state.smartphone = data.smartphone
    state.ses = data.socioeconomic
    state.tech_savvy = data.tech_savvy
    state.woman_member = data.woman_member
    state.corporate = data.corporate
  },
  setCorporateOption: (state, data) => {
    state.dataFilter = data
  },
}