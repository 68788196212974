export default {
  loadRoleCorporate: (state, data) => {
    state.role = data;
  },
  loadDetailRole: (state, data) => {
    let detail = state.detail;

    detail.id = data.id;
    detail.name = data.role_name;

    if (data.access_dashboard === 1) {
      detail.access_dashboard = true;
    } else {
      detail.access_dashboard = false;
    }
  },
  loadDetailPermissionRole: (state, data) => {
    let detail = state.detail;

    detail.permission = data;
  },
};