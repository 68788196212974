import axios from '@/libs/axios'
import Vue from 'vue';

// context.rootState.config call root state in store/index.js

export default {
  loadBanner: context => {
    axios.get(`/banner`, context.rootState.config)
      .then(res => {
        let data = res.data.data
        context.commit('loadBanner', data)
      })
  },
  loadAllBanner: context => {
    axios.get(`/banner/all`, context.rootState.config)
      .then(res => {
        let data = res.data.data
        context.commit('loadBanner', data)
      })
  },
  loadDetailBanner: (context, id) => {
    axios.get(`/banner/${id}`, context.rootState.config)
      .then(res => {
        let data = res.data.data
        context.commit('loadDetailBanner', data)
      })
  },

  addBanner: (context, data) => {
    return new Promise((resolve, reject) => {
      axios.post(`/banner`, data, context.rootState.config)
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  },
  editBanner: (context, data) => {
    return new Promise((resolve, reject) => {
      axios.post(`/banner/${data.id}`, data, context.rootState.config)
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  },
  deleteBanner: (context, id) => {
    return new Promise((resolve, reject) => {
      axios.delete(`/banner/${id}`, context.rootState.config)
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  },
  resetBanner: context => {
    context.commit('resetBanner')
  }
}