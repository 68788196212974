import axios from '@/libs/axios'
import Vue from 'vue';

// context.rootState.config call root state in store/index.js

export default {
  loadCountries: context => {
    axios.get('csc/countries')
      .then(res => {
        let data = res.data.data
				context.commit('loadCountries', data)
      })
  },
  loadStatesByCountry: (context, country) => {
    axios.get(`csc/countries/${country}/states`)
      .then(res => {
        let data = res.data.data
				context.commit('loadStates', data)
      })
  },
  loadCitiesByStates: (context, state) => {
    axios.get(`csc/states/${state}/city`)
      .then(res => {
        let data = res.data.data
				context.commit('loadCities', data)
      })
  },
  resetState: context => {
    context.commit('resetState')
  },
  resetCity: context => {
    context.commit('resetCity')
  }
}