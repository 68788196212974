export default {
  partner: [],
  detail: {
    id: null,
    title: null,
    title_parent: null,
    description: null,
    date: null,
    pin_code: null,
    category: null,
    has_parent: false,
    parent: null,
    logo: null,
    file: {
      url: null,
      image: null
    },
    file_raw: null
  },
}