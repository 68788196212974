import axios from '@/libs/axios'
import Vue from 'vue';

// context.rootState.config call root state in store/index.js

export default {
  loadCorporate: (context) => {
    axios.get(`/corporate`, context.rootState.config).then((res) => {
      let data = res.data.data;
      let corporate = [];

      data.corporate.forEach((item) => {
        let contact = item.contact_name === null ? "-" : item.contact_name;
        let phone_code = item.corporate_phonecode === null ? "" : item.corporate_phonecode;
        let phone = `${phone_code} ${item.corporate_phone}`;

        let target_member = item.corporate_total_member;
        let total_member = item.total_member_av;

        let expired_date = '-';
        if(item.member) {
          expired_date = item.member.length > 0 ? item.member[0].expired_date : '-';
        }

        let corporateItem = {
          id: item.id,
          name: item.corporate_name,
          logo: item.logo,
          contact: contact,
          phone: phone,
          target_member: target_member,
          total_member: total_member,
          member_text: `${total_member} of ${target_member} Members`,
          expired_date: expired_date,
        };

        corporate.push(corporateItem);
      });

      let corporateData = {
        "corporate": corporate,
        "corporate_active": data.corporate_active,
        "corporate_inactive": data.corporate_inactive,
        "corporate_exp": data.corporate_exp,
        "corporate_limit": data.corporate_limit
      }
      context.commit("loadCorporate", corporateData);
    });
  },
  loadDetailCorporate: (context, id) => {
    axios.get(`/corporate/${id}`, context.rootState.config).then((res) => {
      let data = res.data.data;
      context.commit("loadDetailCorporate", data);
      context.commit("loadCorporateMembers", data.member);
    });
  },
  loadSocmedCorporate: (context, id) => {
    axios.get(`/member/corporate/socialmedia/${id}`, context.rootState.config).then((res) => {
      let data = res.data.data;
      context.commit("loadSocmedCorporate", data);
    });
  },
  loadAddressCorporate: (context, id) => {
    axios.get(`/member/corporate/address/${id}`, context.rootState.config).then((res) => {
      let data = res.data.data;
      context.commit("loadAddressCorporate", data);
    });
  },
  loadBannerCorporate: (context, id) => {
    axios.get(`/banner/corporate/${id}`, context.rootState.config).then((res) => {
      let data = res.data.data;
      context.commit("loadBannerCorporate", data);
    });
  },
  loadPartnerCorporate: (context, id) => {
    axios.get(`/partner/corporate/${id}`, context.rootState.config).then((res) => {
      let data = res.data.data.partner;
      context.commit("loadPartnerCorporate", data);
    });
  },
  loadEventCorporate: (context, id) => {
    axios.get(`/event/corporate/${id}`, context.rootState.config).then((res) => {
      let data = res.data.data;
      context.commit("loadEventCorporate", data);
    });
  },

  // corporate socmed actions
  loadDetailSocmed: (context, id) => {
    axios.get(`/member/corporate/socialmedia/id/${id}`, context.rootState.config).then((res) => {
      let data = res.data.data;
      context.commit("loadDetailSocmed", data);
    });
  },
  addSocmedCorporate: (context, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/member/corporate/socialmedia", data, context.rootState.config)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  },
  editSocmedCorporate: (context, data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`/member/corporate/socialmedia/${data.id}`, data, context.rootState.config)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  },
  deleteSocmedCorporate: (context, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/member/corporate/socialmedia/${id}`, context.rootState.config)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  },
  resetCorporateSocmed: (context) => {
    context.commit("resetCorporateSocmed");
  },

  // corporate address actions
  loadDetailAddress: (context, id) => {
    axios.get(`/member/corporate/address/id/${id}`, context.rootState.config).then((res) => {
      let data = res.data.data;
      context.commit("loadDetailAddress", data);
    });
  },
  addAddressCorporate: (context, data) => {
    return new Promise((resolve, reject) => {
      let address = {
        corporate_id: data.corporate_id,
        corporate_address: data.address,
        corporate_country: data.country,
        corporate_state: data.state,
        corporate_city: data.city,
        corporate_postal_code: data.postal_code,
        set_default: data.default ? "Yes" : null,
        order: data.order,
      };

      axios
        .post("/member/corporate/address", address, context.rootState.config)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  },
  editAddressCorporate: (context, data) => {
    let address = {
      corporate_address: data.address,
      corporate_country: data.country,
      corporate_state: data.state,
      corporate_city: data.city,
      corporate_postal_code: data.postal_code,
      set_default: data.default ? "Yes" : null,
      order: data.order,
    };

    return new Promise((resolve, reject) => {
      axios
        .put(`/member/corporate/address/${data.id}`, address, context.rootState.config)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  },
  resetCorporateAddress: (context) => {
    context.commit("resetCorporateAddress");
  },
};