export default {
  loadPartner: (state, data) => {
    state.partner = data
  },
  loadDetailPartner: (state, data) => {
    let detail = state.detail

    let start_date_split = data.start_date.split("-")
    let end_date_split = data.end_date.split("-")
    let start_date = `${start_date_split[2]}-${start_date_split[1]}-${start_date_split[0]}`
    let end_date = `${end_date_split[2]}-${end_date_split[1]}-${end_date_split[0]}`
    let range_date = `${start_date} to ${end_date}`

    detail.id = data.id
    detail.title = data.name
    detail.description = data.description
    detail.date = range_date
    detail.pin_code = data.pin_code
    detail.category = data.category
    detail.logo = data.image
    detail.file.url = data.image
  },
  resetPartner: state => {
    let detail = state.detail

    detail.id = null
    detail.title = null
    detail.title_parent = null
    detail.description = null
    detail.date = null
    detail.pin_code = null
    detail.category = null
    detail.has_parent = false
    detail.parent = null
    detail.file = {
      url: null,
      image: null
    }
    detail.file_raw = null
  }
}