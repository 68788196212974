import axios from "@/libs/axios";
import Vue from "vue";

// context.rootState.config call root state in store/index.js

export default {
  loadSurvey: (context) => {
    axios.get(`/survey`, context.rootState.config).then((res) => {
      let data = res.data.data;
      context.commit("loadSurvey", data);
    });
  },
  loadDetailSurvey: (context, id) => {
    return new Promise((resolve, reject) => {
      axios.get(`/survey/${id}`, context.rootState.config)
      .then((res) => {
        let data = res.data.data;
        context.commit("loadDetailSurvey", data);
        resolve(res);
      })
      .catch((error) => reject(error));
    });
  },
  loadConfigSurvey: (context, id) => {
    return new Promise((resolve, reject) => {
      axios.get(`/survey/config/${id}`, context.rootState.config)
      .then((res) => {
        let data = res.data.data;
        resolve(res);
      })
      .catch((error) => reject(error));
    });
  },
  loadDetailsSurvey: (context, id) => {
    return new Promise((resolve, reject) => {
      axios.get(`/survey/answer/${id}`, context.rootState.config)
      .then((res) => {
        let data = res.data.data;
        resolve(res);
      })
      .catch((error) => reject(error));
    });
  },
  addSurvey: (context, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/survey`, data, context.rootState.config)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  },
  addConfigSurvey: (context, data) => {
    return new Promise((resolve, reject) => {
      axios.post(`/survey/config/`, data, context.rootState.config)
      .then((res) => {
        let data = res.data.data;
        resolve(res);
      })
      .catch((error) => reject(error));
    });
  },
  editSurvey: (context, data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`/survey/${data.id}`, data, context.rootState.config)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  },
  deleteSurvey: (context, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/survey/${id}`, context.rootState.config)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  },
  deleteDetail: (context, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/survey/detail/${id}`, context.rootState.config)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  },
};
