import axios from '@/libs/axios'
import Vue from 'vue';

// context.rootState.config call root state in store/index.js

export default {
  loadEventCorporate: context => {
    axios.get(`/event`, context.rootState.config)
      .then(res => {
        let data = res.data.data
        let events = []
        
        data.map(item => {
          let event = {
            id: item.id,
            name: item.name,
            image: item.image,
            start_date: item.start_date.replace(" | ", " "),
            end_date: item.end_date.replace(" | ", " "),
            created_by: item.created_by,
            location: item.location,
            status: item.status
          }

          events.push(event)
        })

        context.commit('loadEvent', events)
      })
  },
  loadEventAll: (context, status = 'all') => {
    if(status !== 'all') {
      status = `?status=${status}`
    } else {
      status = ''
    }

    axios.get(`/event/all${status}`, context.rootState.config)
      .then(res => {
        let data = res.data.data
        let events = []
        
        if(data.length > 0) {
          data.map(item => {
            let event = {
              id: item.id,
              name: item.name,
              image: item.image,
              start_date: item.start_date.replace(" | ", " "),
              end_date: item.end_date.replace(" | ", " "),
              created_by: item.created_by,
              location: item.location,
              status: item.status,
              corporate: item.corporate
            }
            
            events.push(event)
          })
        }

        context.commit('loadEvent', events)
      })
  },
  loadDetailEvent: (context, id) => {
    axios.get(`/event/${id}?participant=1`, context.rootState.config)
      .then(res => {
        let data = res.data.data

        context.commit('loadEventDetail', data)
        context.commit('loadEventParticipants', data.participants)
      })
  },
  loadLogEvent: context => {
    axios.get('/log/event', context.rootState.config)
      .then(res => {
        let data = res.data.data
        let events = []
        
        data.map(item => {
          let event = {
            id: item.id,
            name: item.name,
            image: item.image,
            start_date: item.start_date.replace(" | ", " "),
            end_date: item.end_date.replace(" | ", " "),
            created_by: item.created_by,
            location: item.location,
            status: item.status
          }

          events.push(event)
        })

        context.commit('loadEventLog', events)
      })
  },
  loadLogEventAll: context => {
    axios.get(`/event/all?status=done`, context.rootState.config)
      .then(res => {
        let data = res.data.data
        let events = []
        
        if(data.length > 0) {
          data.map(item => {
            let event = {
              id: item.id,
              name: item.name,
              image: item.image,
              start_date: item.start_date.replace(" | ", " "),
              end_date: item.end_date.replace(" | ", " "),
              created_by: item.created_by,
              location: item.location,
              status: item.status,
              corporate: item.corporate
            }
            
            events.push(event)
          })
        }

        context.commit('loadEventLog', events)
      })
  },
  addEvent: (context, data) => {
    return new Promise((resolve, reject) => {
      axios.post(`/event`, data, context.rootState.config)
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  },
  editEvent: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.post(`/event/${payload.id}?_method=PUT`, payload.data, context.rootState.config)
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  },
  deleteEvent: (context, id) => {
    return new Promise((resolve, reject) => {
      axios.delete(`/event/${id}`, context.rootState.config)
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  },
  resetEvent: (context, data) => {
    context.commit('resetEvent')
  }
}