export default {
  loadMember: (state, data) => {
    const member = data.map(item => {
      let memberMiddleName = ""
      let memberLastName = ""

      if(item.member_middle_name) {
        memberMiddleName = ` ${item.member_middle_name}`
      }

      if(item.member_last_name) {
        memberLastName = ` ${item.member_last_name}`
      }

      let memberName = `${item.member_first_name}${memberMiddleName}${memberLastName}`

      let phone = '-'
      let phoneCode = null

      if(item.member_phone) {
        phoneCode = (item.phone_code === null ? '' : '+' + item.phone_code)
        phone = `${phoneCode}${item.member_phone}`
      }

      return {
        id: item.id,
        user_id: item.user_id,
        name: memberName,
        logo: item.logo,
        phone: phone,
        email: item.member_email,
        status: item.member_status,
        corporate: item.corporate_name,
        corporate_id: item.corporate_id,
        divisi: item.divisi_name
      }
    })

    state.members = member
  },
  loadMemberDetail: (state, data) => {
    let detail = state.detail

    let middle_name = ""
    let last_name = ""

    if(data.member_middle_name) {
      middle_name = ` ${data.member_middle_name}`
    }

    if(data.member_last_name) {
      last_name = ` ${data.member_last_name}`
    }

    detail.id = data.id
    detail.corporate_id = data.corporate_id
    detail.name = `${data.member_first_name}${middle_name}${last_name}`
    detail.first_name = data.member_first_name
    detail.middle_name = data.member_middle_name
    detail.last_name = data.member_last_name
    detail.address = data.member_address
    detail.country = data.member_country
    detail.state = data.member_state
    detail.city = data.member_city 
    detail.email = data.member_email
    detail.username = data.user.username
    detail.status = data.member_status
    detail.gender = (data.member_gender) ? '-' : data.member_gender
    detail.phone_code = data.phone_code
    detail.contact_phone = data.member_phone
    detail.gender = data.member_gender
    detail.dob = data.member_dob
    detail.work = data.member_work
    detail.occupation = data.member_occupation
    detail.education = data.member_education
    detail.provider = data.member_provider
    detail.phone_type = data.member_phone_type
    detail.savvy = (data.member_text_savvy == 1) ? true : false
    detail.photo_profile = data.logo
    detail.profile = data.profil
    detail.role = data.role
  },
  loadMemberCorporate: (state, data) => {
    if(data.length > 0) {
      state.coprorate = data
    }
  },
  loadMemberCompany: (state, data) => {
    let company = data.map(item => {
      return {
        name: item.company_name,
        department: item.company_department,
        division: item.company_division,
        address: item.company_address,
        country: item.company_country,
        state: item.company_state,
        city: item.company_city,
        postal_code: item.company_postal_code
      }
    })

    state.company = company
  },
  loadMemberSocmed: (state, data) => {
    let socmed = data.map(item => {
      return {
        title: item.socmed_title,
        link: item.socmed_link
      }
    })

    state.socmed = socmed
  },
  loadMemberAddress: (state, data) => {
    let address = data.map(item => {
      return {
        address: item.address,
        country: item.address_country,
        state: item.address_state,
        city: item.address_city,
        postal_code: item.address_postal_code,
        order: item.order
      }
    })

    state.address = address
  },
  resetDetailMember: state => {
    state.detail = {
      id: null,
      corporate_id: null,
      name: null,
      first_name: null,
      middle_name: null,
      last_name: null,
      address: null,
      country: null,
      state: null,
      city: null,
      email: null,
      username: null,
      status: null,
      gender: null,
      phone_code: null,
      phone_number: null,
      gender: null,
      date_of_birth: null,
      bussiness_category: null,
      occupation: null,
      education: null,
      provider: null,
      phone_type: null,
      text_savvy: null,
      profile: null,
      photo_profile: null
    }

    state.corporate = {
      photo: null,
      name: null,
      divisi: null
    }

    state.address = []
    state.socmed = []
    state.company = []
  },
  eventMember: (state, data) => {
    state.event_member = {
      status: data.status,
      message: data.message
    }
  }
}