import axios from '@/libs/axios'

export default {
  loadPartner: context => {
    axios.get(`/partner`, context.rootState.config)
      .then(res => {
        let data = res.data.data
        
        let partner = data.parent.map(item => {
          let corporate_id = null
          let corporate_name = null

          if(item.corporate !== null) {
            corporate_id = item.corporate.id
            corporate_name = item.corporate.name
          }

          return {
            id: item.id,
            name: item.name,
            image: item.image,
            expired: item.is_expired,
            date: item.date,
            pin_code: item.pin_code,
            corporate: corporate_name,
            corporate_id: corporate_id,
            cabang: item.cabang ? item.cabang.length : 0
          }
        })

        context.commit('loadPartner', partner)
      })
  },
  loadDetailPartner: (context, id) => {
    axios.get(`/partner/${id}`, context.rootState.config)
      .then(res => {
        let data = res.data.data
        console.log(data)
        context.commit('loadDetailPartner', data)
      })
  },
  addPartner: (context, data) => {
    return new Promise((resolve, reject) => {
      axios.post('partner', data, context.rootState.config)
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  },
  editPartner: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.post(`partner/${payload.id}?_method=PUT`, payload.data, context.rootState.config)
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  },
  deletePartner: (context, id) => {
    return new Promise((resolve, reject) => {
      axios.delete(`/partner/${id}`, context.rootState.config)
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  },
  resetPartner: context => {
    context.commit('resetPartner')
  }
}