export default {
  members: [],
  detail: {
    id: null,
    corporate_id: null,
    name: null,
    first_name: null,
    middle_name: null,
    last_name: null,
    address: null,
    country: null,
    state: null,
    city: null,
    email: null,
    username: null,
    status: null,
    gender: null,
    phone_code: null,
    phone_number: null,
    gender: null,
    dob: null,
    bussiness_category: null,
    occupation: null,
    education: null,
    provider: null,
    phone_type: null,
    text_savvy: null,
    profile: null,
    photo_profile: null
  },
  corporate: {
    photo: null,
    name: null,
    divisi: null
  },
  address: [],
  socmed: [],
  company: [],

  event_member: {
    status: null,
    message: null
  }
}