import axios from '@/libs/axios'
import Vue from 'vue';

// context.rootState.config call root state in store/index.js

export default {
  loadDashboard({ commit, getters }) {
    const dataFilter = getters.getCorporateId
    const data = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        corporate_id: dataFilter.corporate ? dataFilter.corporate : null,
        start_date: dataFilter.start_date ? dataFilter.start_date : null,
        end_date: dataFilter.end_date ? dataFilter.end_date : null,
      }
    };
    axios.get("home", data)
    .then((res) => {
      let data = res.data.data;
      commit("loadDashboard", data);
    });
  },
  async setCorporateOptions({ dispatch, commit }, data) {
    await commit("setCorporateOption", data);
    setTimeout(() => {
      dispatch("loadDashboard");
    }, 250)
  },
};