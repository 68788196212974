import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // timeout: 1000,
  baseURL: `https://api.the-netwerk.com/api/mjVmBSJ31Hx2Ag9GCOUS`,
})

Vue.prototype.$http = axiosIns

export default axiosIns