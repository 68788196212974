import Vue from 'vue';

Vue.filter('handleNullValue', value => {
  if(value === null || value === 'null') {
    return "-"
  } else {
    return value
  }
})

Vue.filter('initial', value => {
  var result = "";
  let tokens = value.split("")

  if(tokens.length == 1) {
    result = tokens[0].substring(0,1).toUpperCase()
  } else {
    for(let i=0; i<2; i++){
      result += tokens[i].substring(0,1).toUpperCase()
    }
  }

  return result
})

Vue.filter('dotConcat', (value, length = 25) => {
  let result = ""

  if(value) {
    let length_text = value.split("")

    if(length_text.length > length) {
      result = `${value.substring(0, length)}...`
    } else {
      result = value
    }

    return result
  } else {
    return "-"
  }
})

Vue.filter('phone', (value, code, no) => {
  if(value) {
    if(code) {
      return `${code} ${no}`
    } else {
      return `(62) ${no}`
    }  
  } else {
    return '-'
  }
})