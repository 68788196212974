import state from "./state";

export default {
  loadSurvey: (state, data) => {
    state.survey = data;
  },
  loadDetailSurvey: (state, data) => {
    state.detail = data;
  },
};
